import React from 'react';
import Container from 'react-bootstrap/Container';
import jujutsuBanner from './assets/jujutsuBanner.jpeg';
import AlertDismiss from './AlertDismiss';

function Jujutsu() {
    return (
        <Container>
            <img src={jujutsuBanner} className="img-fluid chainsawImg" alt="Jujutsu banner" style={{ borderRadius: 4 }} />
            <AlertDismiss />
            <ul className="list-group" style={{ marginTop: 10 }}>
                <li className="list-group-item list-group-item-action">Chapter 1<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-1-ryomen-sukuna/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 2<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-2-secret-execution/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 3<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-3-for-myself/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 4<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-4-steel-beam-girl/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 5<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-5-beginning/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 6<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-6-the-crused-womb-s-earthly-existence/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 7<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-7-the-crused-womb-s-earthly-existence-2/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 8<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-8-the-cursed-womb-s-earthly-existence-3/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 9<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-9-the-cursed-womb-s-earthly-existence-4/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 10<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-10-after-the-rain/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 11<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-11-a-dream/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 12<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-12-pushing-forward/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 13<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-13-movie-appreciation/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 14<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-14-a-sudden-attack/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 15<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-15-expansion/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 16<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-16-compassion/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 17<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-17-boredom/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 18<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-18-low-class/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 19<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-19/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 20<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-20-small-fry-and-reverse-retribution-2/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 21<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-21-small-fry-and-reverse-retribution-3/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 22<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-22/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 23<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-23-small-fry-and-reverse-retribution-v/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 24<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-24-small-fry-and-reverse-retribution-vi/">Read</a></span></li>
                <li className="list-group-item list-group-item-action">Chapter 25<span style={{ float: 'right' }} className="badge rounded-pill bg-primary"><a style={{color: "white", textDecoration: "none"}} href="https://w3.readjujutsu.com/manga/jujutsukaisen-chapter-1-2-25-narrow-minded-foolishness/">Read</a></span></li>
            </ul>
        </Container>
    );
}


export default Jujutsu;